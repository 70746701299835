import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  private apiUrl = environment.projectsApiUrl;
  private baseUrl = environment.projectsBaseUrl;

  constructor(private http: HttpClient, private toastr: ToastrService) { }

  getAllProjects() {
    return this.http.get(`${this.apiUrl}`)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  getProjectDetailsById(projectId: string) {
    return this.http.get(`${this.apiUrl}/${projectId}`)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  getProjectById(projectId: string,includeRegions:boolean){
    //To get associated regions and environment of a project
    const params = new HttpParams()
      .set('includeRegions', includeRegions);
    return this.http.get(`${this.apiUrl}/${projectId}`,{ params })
    //return this.http.get(`https://96855e6f-fcef-405a-8e3f-6b4cf48f7a4b.mock.pstmn.io/project-management/v1/projects/8910b3b1-d25e-4191-80f1-5d8fa5a66db6?includeRegions=true`)
    .pipe(
      catchError(this.handleError.bind(this))
    );
  }

  getEnvironmentsByProject(projectId: string) {
    return this.http.get(`${this.apiUrl}/${projectId}/environments`)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  getDeploymentsByProject(projectId: string) {
    return this.http.get(`${this.apiUrl}/${projectId}/deployments`)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  createProject(req: any) {
    return this.http.post(`${this.apiUrl}/`, req)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  updateProject(projectId: string, req: any) {
    return this.http.put(`${this.apiUrl}/${projectId}`, req)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  deleteProject(projectId: string) {
    return this.http.delete(`${this.apiUrl}/${projectId}`)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  createEnvironment(projectId: string, req: any){
    return this.http.post(`${this.apiUrl}/${projectId}/environments`, req)
    .pipe(
      catchError(this.handleError.bind(this))
    );
  }

  updateEnvironment(projectId: string, envId: string, req: any) {
    return this.http.put(`${this.apiUrl}/${projectId}/environments/${envId}`, req)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  deleteEnvironment(projectId: string, envId: string) {
    return this.http.delete(`${this.apiUrl}/${projectId}/environments/${envId}`)
    //return this.http.delete(`https://e7d27103-7e26-4c37-8b1f-b3dcd1d1b2aa.mock.pstmn.io/project-management/v1/projects/d5492734-616e-4715-b891-ac6a71b8e945/environments/env-7ztr94`)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  getPlanLimits(plan:string){
    return this.http.get(`${this.baseUrl}/plans/${plan}`)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
      this.toastr.error(error.error?.error)
    }
    else if (error.status === 500 && error.error && error.error?.error.details) {
      const errorMessage = error.error?.error.details;
      console.error('Internal Server Error 500:', errorMessage);
      this.toastr.error(errorMessage,'Internal Server Error 500:')
    } 
    else if (error.status === 404 && error.error && error.error?.error.details) {
      const errorMessage = error.error?.error.details;
      console.error('Internal Server Error 404:', errorMessage);
      this.toastr.error(errorMessage,'Internal Server Error 404:' )
    } 
    else if (error.status === 400) {
      const errorMessage = error.error?.message || 'Bad Request';
      console.error('Bad Request:', errorMessage); 
      this.toastr.error(errorMessage);
    }
    else {
       const errorMessage = error.error?.error || 'Please try again later';
      // this.toastr.error(errorMessage, 'Error');
      console.error(errorMessage);
    }
    return throwError('Something bad happened; please try again later.');
  }

}
